import React, { useState, useRef, useEffect } from 'react';
import { Mic, StopCircle, VolumeX, Volume2, Loader, Clock } from 'lucide-react';

const Button = ({ onClick, disabled, children, variant }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-6 py-3 rounded-full font-semibold transition-all duration-300 flex items-center justify-center shadow-md hover:shadow-lg ${
      variant === 'destructive'
        ? 'bg-red-500 hover:bg-red-600 text-white'
        : variant === 'secondary'
        ? 'bg-gray-500 hover:bg-gray-600 text-white'
        : 'bg-blue-600 hover:bg-blue-700 text-white'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
  >
    {children}
  </button>
);

const Input = ({ type, placeholder, value, onChange, className }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`border rounded px-3 py-2 w-full ${className}`}
  />
);

const MultiModalVoiceBot = () => {
  const [apiKey, setApiKey] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    audioRef.current.onended = () => setIsPlaying(false);
    return () => {
      audioRef.current.pause();
      audioRef.current.src = '';
    };
  }, []);

  const startListening = () => {
    setIsListening(true);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };
        mediaRecorder.current.onstop = processAudio;
        mediaRecorder.current.start();
      })
      .catch(err => {
        console.error("Error accessing the microphone:", err);
        alert("Unable to access the microphone. Please check your browser settings.");
        setIsListening(false);
      });
  };

  const stopListening = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
      mediaRecorder.current.stop();
      setIsListening(false);
    }
  };

  const processAudio = async () => {
    setIsProcessing(true);
    const audioBlob = new Blob(audioChunks.current, { type: 'audio/mpeg' });
    
    const transcriptionStartTime = performance.now();
    const transcription = await speechToText(audioBlob);
    const transcriptionEndTime = performance.now();

    const chatStartTime = performance.now();
    const response = await getChatCompletion(transcription);
    const chatEndTime = performance.now();

    const audioStartTime = performance.now();
    const imageStartTime = performance.now();
    const [audioUrl, imageUrl] = await Promise.all([
      textToSpeech(response),
      generateImage(response)
    ]);
    const audioEndTime = performance.now();
    const imageEndTime = performance.now();

    const transcriptionTime = transcriptionEndTime - transcriptionStartTime;
    const chatTime = chatEndTime - chatStartTime;
    const audioTime = audioEndTime - audioStartTime;
    const imageTime = imageEndTime - imageStartTime;

    setConversation(prev => [{
      role: 'user',
      content: transcription,
      transcriptionTime
    }, {
      role: 'assistant',
      content: response,
      audioUrl,
      imageUrl,
      chatTime,
      audioTime,
      imageTime
    }, ...prev]);
    
    playAudio(audioUrl);
    setIsProcessing(false);
    audioChunks.current = [];
  };

  const speechToText = async (audioBlob) => {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');
    formData.append('model', 'whisper-1');

    try {
      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.text;
    } catch (error) {
      console.error('Error in speech to text:', error);
      return 'Error in transcription. Please try again.';
    }
  };

  const getChatCompletion = async (message) => {
    const url = "https://api.openai.com/v1/chat/completions";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "gpt-3.5-turbo",
      "messages": [
        {
          "role": "system",
          "content": "You are a helpful assistant."
        },
        {
          "role": "user",
          "content": message
        }
      ]
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    } catch (error) {
      console.error('Error in chat completion:', error);
      return 'Error in processing. Please try again.';
    }
  };

  const textToSpeech = async (text) => {
    const url = "https://api.openai.com/v1/audio/speech";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "tts-1",
      "input": text,
      "voice": "alloy"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const audioBlob = await response.blob();
      return URL.createObjectURL(audioBlob);
    } catch (error) {
      console.error('Error in text to speech:', error);
      return null;
    }
  };

  const generateImage = async (prompt) => {
    const url = "https://api.openai.com/v1/images/generations";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "dall-e-2",
      "prompt": prompt,
      "n": 1,
      "size": "256x256"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.data[0].url;
    } catch (error) {
      console.error('Error generating image:', error);
      return null;
    }
  };

  const playAudio = (audioUrl) => {
    if (isPlaying) {
      audioRef.current.pause();
    }
    audioRef.current.src = audioUrl;
    audioRef.current.play();
    setIsPlaying(true);
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const formatTime = (ms) => {
    if (ms < 1000) {
      return `${ms.toFixed(0)} ms`;
    } else {
      return `${(ms / 1000).toFixed(2)} s`;
    }
  };

  return (
    <div className="p-8 max-w-4xl mx-auto bg-gradient-to-b from-gray-50 to-white rounded-lg shadow-xl border border-gray-200">
      <h1 className="text-4xl font-bold mb-2 text-center text-gray-800">Multi-Modal Voice Bot</h1>
      <h2 className="text-xl mb-8 text-center text-gray-600">Talk with an AI assistant</h2>
      <Input
        type="password"
        placeholder="Enter your OpenAI API key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        className="mb-4"
      />
      <div className="flex justify-center mb-6 space-x-4">
        {!isListening ? (
          <Button onClick={startListening} disabled={!apiKey || isProcessing}>
            <Mic className="mr-2 h-5 w-5" /> Start Listening
          </Button>
        ) : (
          <Button onClick={stopListening} variant="destructive">
            <StopCircle className="mr-2 h-5 w-5" /> Stop Listening
          </Button>
        )}
        {isPlaying ? (
          <Button onClick={stopAudio} variant="secondary">
            <VolumeX className="mr-2 h-5 w-5" /> Stop Audio
          </Button>
        ) : (
          <Button onClick={() => conversation[0]?.audioUrl && playAudio(conversation[0].audioUrl)} disabled={!conversation.length} variant="secondary">
            <Volume2 className="mr-2 h-5 w-5" /> Play Last Response
          </Button>
        )}
      </div>
      {isProcessing && (
        <div className="text-center text-gray-600 mb-4 flex items-center justify-center">
          <Loader className="animate-spin mr-2 h-5 w-5" />
          Processing your request...
        </div>
      )}
      <div className="mt-6 space-y-4">
        {conversation.map((message, index) => (
          <div key={index} className={`p-4 rounded-lg ${message.role === 'user' ? 'bg-blue-100' : 'bg-green-100'}`}>
            <div className="flex flex-col md:flex-row md:items-start justify-between">
              <div className="flex-grow pr-4">
                <p className="font-semibold">{message.role === 'user' ? 'You' : 'Assistant'}:</p>
                <p>{message.content}</p>
                {message.role === 'user' && (
                  <p className="text-sm text-gray-500 mt-1">
                    <Clock className="inline mr-1 h-4 w-4" />
                    Transcription: {formatTime(message.transcriptionTime)}
                  </p>
                )}
                {message.role === 'assistant' && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <Clock className="inline mr-1 h-4 w-4" />
                      Chat: {formatTime(message.chatTime)}
                    </p>
                    {message.audioUrl && (
                      <div className="mt-1">
                        <button onClick={() => playAudio(message.audioUrl)} className="text-blue-600 hover:text-blue-800">
                          <Volume2 className="inline mr-1" /> Play Audio
                        </button>
                        <span className="ml-2 text-sm text-gray-500">
                          <Clock className="inline mr-1 h-4 w-4" />
                          Audio: {formatTime(message.audioTime)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {message.role === 'assistant' && message.imageUrl && (
                <div className="mt-2 md:mt-0 flex-shrink-0">
                  <img src={message.imageUrl} alt="Generated image" className="w-32 h-32 rounded-lg shadow-md" />
                  <p className="text-sm text-gray-500 mt-1">
                    <Clock className="inline mr-1 h-4 w-4" />
                    Image: {formatTime(message.imageTime)}
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiModalVoiceBot;