import React, { useState, useEffect } from 'react';
import VoiceTranslationChatTTS from './components/VoiceTranslationChatTTS';
import MultiModalVoiceBot from './components/MultiModalVoiceBot';
import SimpleVoiceBot from './components/SimpleVoiceBot';

const NavButton = ({ onClick, active, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-md font-semibold transition-all duration-300 ${
      active ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
    }`}
  >
    {children}
  </button>
);

const CustomAlert = ({ message, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <p className="mb-4 text-gray-800">{message}</p>
      <button 
        onClick={onClose}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
      >
        OK
      </button>
    </div>
  </div>
);

const App = () => {
  const [activePage, setActivePage] = useState('demo');
  const [microphonePermission, setMicrophonePermission] = useState('prompt');
  const [isInIframe, setIsInIframe] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const checkIframeAndPermissions = async () => {
      const inIframe = window.self !== window.top;
      setIsInIframe(inIframe);
      console.log('Is running in iframe:', inIframe);

      try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
        console.log('Microphone permission status:', permissionStatus.state);
        setMicrophonePermission(permissionStatus.state);

        permissionStatus.onchange = () => {
          console.log('Microphone permission status changed:', permissionStatus.state);
          setMicrophonePermission(permissionStatus.state);
        };
      } catch (error) {
        console.error('Error checking microphone permission:', error);
      }
    };

    checkIframeAndPermissions();
  }, []);

  const showCustomAlert = (message) => {
    setAlertMessage(message);
  };

  const handleRequestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone access granted:', stream);
      setMicrophonePermission('granted');
      stream.getTracks().forEach(track => track.stop());
      showCustomAlert('Microphone access granted successfully!');
    } catch (error) {
      console.error('Error requesting microphone access:', error);
      setMicrophonePermission('denied');
      showCustomAlert(`Failed to get microphone access: ${error.name} - ${error.message}`);
      
      if (error.name === 'NotAllowedError') {
        console.log('User denied permission or permission was already denied');
      } else if (error.name === 'NotFoundError') {
        console.log('No microphone found on the device');
      } else if (error.name === 'NotReadableError') {
        console.log('Microphone is already in use by another application');
      }
      
      if (isInIframe) {
        console.log('Application is running in an iframe, which may affect permissions');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-md p-4">
        <div className="max-w-6xl mx-auto flex flex-col sm:flex-row justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4 sm:mb-0">Multi-Modal Multi-Lingual Chat Bot</h1>
          <div className="space-x-4">
            <NavButton onClick={() => setActivePage('demo')} active={activePage === 'demo'}>
              API Demo
            </NavButton>
            <NavButton onClick={() => setActivePage('simplevoicebot')} active={activePage === 'simplevoicebot'}>
              Simple Voice Bot
            </NavButton>
            <NavButton onClick={() => setActivePage('multimodalvoicebot')} active={activePage === 'multimodalvoicebot'}>
              Multi-Modal Voice Bot
            </NavButton>
          </div>
        </div>
      </nav>
      <div className="py-8 px-4 max-w-6xl mx-auto">
        {microphonePermission !== 'granted' && (
          <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
            <p className="font-bold">Microphone Access Required</p>
            <p>Please allow microphone access to use the voice features of this app.</p>
            <button 
              onClick={handleRequestMicrophoneAccess}
              className="mt-2 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
            >
              Request Microphone Access
            </button>
          </div>
        )}
        {isInIframe && (
          <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
            <p className="font-bold">Running in Iframe</p>
            <p>This application is embedded in an iframe, which may affect microphone permissions.</p>
            <p className="mt-2">If you're having trouble accessing the microphone, please try opening the app in a new tab:</p>
            <a 
              href="https://voicebot.tigzig.com" 
              target="_blank" 
              rel="noopener noreferrer"
              className="mt-2 inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Open in New Tab
            </a>
          </div>
        )}
        {activePage === 'demo' && <VoiceTranslationChatTTS />}
        {activePage === 'simplevoicebot' && <SimpleVoiceBot />}
        {activePage === 'multimodalvoicebot' && <MultiModalVoiceBot />}
      </div>
      {alertMessage && (
        <CustomAlert 
          message={alertMessage} 
          onClose={() => setAlertMessage('')} 
        />
      )}
    </div>
  );
};

export default App;