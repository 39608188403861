import React, { useState, useRef, useEffect } from 'react';
import { Mic, StopCircle, Send, Play, Image as ImageIcon, Clock, Loader, VolumeX } from 'lucide-react';

const Button = ({ onClick, disabled, children, variant }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-md font-semibold transition-all duration-300 flex items-center justify-center shadow-md hover:shadow-lg ${
      variant === 'destructive'
        ? 'bg-red-500 hover:bg-red-600 text-white'
        : variant === 'secondary'
        ? 'bg-green-500 hover:bg-green-600 text-white'
        : variant === 'tertiary'
        ? 'bg-purple-500 hover:bg-purple-600 text-white'
        : 'bg-blue-500 hover:bg-blue-600 text-white'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
  >
    {children}
  </button>
);

const Input = ({ type, placeholder, value, onChange, className }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`border rounded px-3 py-2 w-full ${className}`}
  />
);

const VoiceTranslationChatTTS = () => {
  const [apiKey, setApiKey] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [translation, setTranslation] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const [chatResponse, setChatResponse] = useState('');
  const [isChatting, setIsChatting] = useState(false);
  const [isGeneratingSpeech, setIsGeneratingSpeech] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [timers, setTimers] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const audio = audioRef.current;
    audio.onended = () => setIsPlaying(false);
    return () => {
      audio.pause();
      audio.src = '';
    };
  }, []);

  const startRecording = () => {
    setIsRecording(true);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };
        mediaRecorder.current.onstop = sendAudioToOpenAI;
        mediaRecorder.current.start();
      })
      .catch(err => {
        console.error("Error accessing the microphone:", err);
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToOpenAI = async () => {
    setIsTranslating(true);
    const startTime = performance.now();
    const audioBlob = new Blob(audioChunks.current, { type: 'audio/mpeg' });
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.mp3');
    formData.append('model', 'whisper-1');

    try {
      const response = await fetch('https://api.openai.com/v1/audio/translations', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTranslation(data.text);
      const endTime = performance.now();
      setTimers(prev => ({ ...prev, translation: endTime - startTime }));
    } catch (error) {
      console.error('Error:', error);
      setTranslation('Error in translation. Please check your API key and try again.');
    } finally {
      setIsTranslating(false);
      audioChunks.current = [];
    }
  };

  const sendChatCompletion = async () => {
    setIsChatting(true);
    const startTime = performance.now();
    const url = "https://api.openai.com/v1/chat/completions";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "gpt-3.5-turbo",
      "messages": [{"role": "user", "content": translation}]
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setChatResponse(data.choices[0].message.content);
      const endTime = performance.now();
      setTimers(prev => ({ ...prev, chat: endTime - startTime }));
    } catch (error) {
      console.error('Error:', error);
      setChatResponse('Error in chat completion. Please try again.');
    } finally {
      setIsChatting(false);
    }
  };

  const generateSpeech = async () => {
    setIsGeneratingSpeech(true);
    const startTime = performance.now();
    const url = "https://api.openai.com/v1/audio/speech";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "tts-1",
      "input": chatResponse,
      "voice": "alloy"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      const endTime = performance.now();
      setTimers(prev => ({ ...prev, speech: endTime - startTime }));

      // Automatically play the audio
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Error:', error);
      setAudioUrl('');
    } finally {
      setIsGeneratingSpeech(false);
    }
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const generateImage = async () => {
    setIsGeneratingImage(true);
    const startTime = performance.now();
    const url = "https://api.openai.com/v1/images/generations";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${apiKey}`
    };
    const payload = {
      "model": "dall-e-2",
      "prompt": chatResponse,
      "n": 1,
      "size": "256x256"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setImageUrl(data.data[0].url);
      const endTime = performance.now();
      setTimers(prev => ({ ...prev, image: endTime - startTime }));
    } catch (error) {
      console.error('Error:', error);
      setImageUrl('');
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const formatTime = (ms) => {
    if (ms < 1000) {
      return `${ms.toFixed(0)} ms`;
    } else {
      return `${(ms / 1000).toFixed(2)} s`;
    }
  };

  return (
    <div className="p-8 max-w-6xl mx-auto bg-gradient-to-b from-gray-50 to-white rounded-lg shadow-xl border border-gray-200">
      <h1 className="text-4xl font-bold mb-2 text-center text-gray-800">
        OpenAI API Components Demo
      </h1>
      <h2 className="text-xl mb-8 text-center text-gray-600">
        Created in React by Claude Sonnet 3.5
      </h2>
      <Input
        type="password"
        placeholder="Enter your OpenAI API key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        className="mb-4"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Voice Translation */}
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
          <h3 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">Voice Translation</h3>
          <div className="flex justify-center mb-4">
            {!isRecording ? (
              <Button onClick={startRecording} disabled={!apiKey || isTranslating}>
                <Mic className="mr-2 h-5 w-5" /> Start Recording
              </Button>
            ) : (
              <Button onClick={stopRecording} variant="destructive">
                <StopCircle className="mr-2 h-5 w-5" /> Stop Recording
              </Button>
            )}
          </div>
          {isTranslating && <div className="text-center text-gray-600 mb-4">Translating...</div>}
          {translation && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg flex-grow">
              <h4 className="text-lg font-semibold mb-2 text-gray-700">Translation:</h4>
              <p className="text-gray-800">{translation}</p>
              {timers.translation && (
                <p className="text-sm text-gray-500 mt-2">
                  <Clock className="inline mr-1 h-4 w-4" />
                  Time: {formatTime(timers.translation)}
                </p>
              )}
            </div>
          )}
        </div>
        
        {/* Chat Completion */}
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
          <h3 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">Chat Completion</h3>
          <Button onClick={sendChatCompletion} disabled={!translation || isChatting} variant="secondary" className="mb-4">
            <Send className="mr-2 h-5 w-5" /> Send to Chat Completion
          </Button>
          {isChatting && <div className="text-center text-gray-600 mb-4">Processing chat completion...</div>}
          {chatResponse && (
            <div className="mt-4 p-4 bg-gray-100 rounded-lg flex-grow">
              <h4 className="text-lg font-semibold mb-2 text-gray-700">Chat Response:</h4>
              <p className="text-gray-800">{chatResponse}</p>
              {timers.chat && (
                <p className="text-sm text-gray-500 mt-2">
                  <Clock className="inline mr-1 h-4 w-4" />
                  Time: {formatTime(timers.chat)}
                </p>
              )}
            </div>
          )}
        </div>
        
        {/* Text-to-Speech */}
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
          <h3 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">Text-to-Speech</h3>
          <Button onClick={generateSpeech} disabled={!chatResponse || isGeneratingSpeech} variant="tertiary" className="mb-4">
            <Play className="mr-2 h-5 w-5" /> Generate Speech
          </Button>
          {isGeneratingSpeech && <div className="text-center text-gray-600 mb-4">Generating speech...</div>}
          {audioUrl && (
            <div className="mt-4 flex-grow">
              <div className="flex items-center justify-between">
                <audio ref={audioRef} src={audioUrl} className="w-full" controls />
                {isPlaying && (
                  <Button onClick={stopAudio} variant="destructive" className="ml-2">
                    <VolumeX className="mr-2 h-5 w-5" /> Stop
                  </Button>
                )}
              </div>
              {timers.speech && (
                <p className="text-sm text-gray-500 mt-2">
                  <Clock className="inline mr-1 h-4 w-4" />
                  Time: {formatTime(timers.speech)}
                </p>
              )}
            </div>
          )}
        </div>
        
        {/* Image Generation */}
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col">
          <h3 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">Image Generation</h3>
          <Button onClick={generateImage} disabled={!chatResponse || isGeneratingImage} variant="tertiary" className="mb-4">
            <ImageIcon className="mr-2 h-5 w-5" /> Generate Image
          </Button>
          {isGeneratingImage && <div className="text-center text-gray-600 mb-4">Generating image...</div>}
          {imageUrl && (
            <div className="mt-4 flex-grow">
              <img src={imageUrl} alt="Generated image" className="w-full rounded-lg shadow-md" />
              {timers.image && (
                <p className="text-sm text-gray-500 mt-2">
                  <Clock className="inline mr-1 h-4 w-4" />
                  Time: {formatTime(timers.image)}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceTranslationChatTTS;